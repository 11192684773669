* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: clamp(1rem, 2vw, 3rem);
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
}

a {
  color: inherit;
}

.wrapper {
  max-width: 80rem;
  margin: 0 auto;
}

ol {
  padding: 1.5rem 1.5rem 1.5rem 4em;
  border: 0.3rem solid turquoise;
  columns: 25rem;
  column-gap: 7rem;
  column-rule: 4px dotted turquoise;
  border-radius: 0.5rem;
}

ol > * + * {
  margin-top: 1rem;
}

h1 {
  color: black;
}

li {
  break-inside: avoid;
  color: black;
}

::marker {
  content: counters(list-item, "") ": ";
  font-weight: 700;
  font-size: 1.4em;
  color: turquoise;
}

li span {
  display: block;
}

li span:first-child {
  font-size: 1.2em;
}

li span:not(first-child) {
  font-style: italic;
  font-size: 0.8em;
}

.divider {
  min-height: 6rem;
  padding: 2rem;
  background: lavender;
  color:black
}
